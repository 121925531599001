<template>
  <!-- 保险>幼儿校方责任险总部 -->
  <div class="contain">
    <div class="form-header-btn">
      <div class="header-title">幼儿校方责任险</div>
      <div v-if="tabClickIndex && tabClickIndex == 1">
        <el-button
          type="primary"
          class="sheet-btn"
          @click="downLoadFileNo"
          v-if="isUninsuredExport"
        >
          导出
        </el-button>
      </div>
      <div v-else>
        <el-button
          type="primary"
          class="sheet-btn"
          @click="dialogFormVisible = true"
          v-if="isUpload"
        >
          上传保单
        </el-button>
        <el-button
          type="primary"
          class="sheet-btn"
          @click="showFile"
          v-if="isView"
        >
          查看保单
        </el-button>
        <el-button
          type="primary"
          class="sheet-btn"
          @click="downLoadFile"
          v-if="isExport"
        >
          导出
        </el-button>
      </div>
    </div>

    <el-tabs type="border-card" @tab-click="handleTabClick">
      <el-tab-pane>
        <span slot="label">全部</span>
        <div>
          <!-- 表单 -->
          <el-form
            :model="form"
            onsubmit="return false"
            :inline="true"
            class="search-form"
          >
            <el-form-item>
              <el-select
                v-model="form.orgId"
                clearable
                placeholder="请选择分公司"
                @change="handleCompany"
              >
                <el-option
                  v-for="item in moduleCompanyOptions"
                  :key="item.label"
                  :label="item.name"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-select
                v-model="form.parkId"
                clearable
                placeholder="请选择园校"
                :disabled="disabledPark"
                @change="handlePark"
              >
                <el-option
                  v-for="item in moduleParkOptions"
                  :key="item.label"
                  :label="item.name"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-select
                v-model="form.classLabel"
                clearable
                placeholder="请选择班级"
                :disabled="disabledClass"
              >
                <el-option
                  v-for="item in moduleClassOptions"
                  :key="item.label"
                  :label="item.name"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-input
                v-model.trim="form.policyholder"
                placeholder="投保人"
                clearable
                class="select-condition"
              />
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchFun"
              >
                查询
              </el-button>
            </el-form-item>

            <el-form-item>
              <el-button
                @click="resetFun"
                icon="el-icon-refresh-left"
                class="reset"
              >
                重置
              </el-button>
            </el-form-item>
          </el-form>

          <!-- 表格 -->
          <el-table
            :data="tableData"
            :header-cell-style="{
              background: '#F1F4F9',
              color: '#5E6D9B',
              fontWeight: '400',
              'text-align': 'center',
            }"
            v-loading="tableLoading"
          >
            <el-table-column
              type="index"
              label="序号"
              min-width="80"
              align="center"
            ></el-table-column>
            <!--            <el-table-column
              type="index"
              label="各园区小序号"
              width="110"
              align="center"
            ></el-table-column>-->
            <el-table-column
              prop="insuranceType"
              label="保险类型"
              min-width="110"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="policyholder"
              label="投保人"
              min-width="110"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="identityNumber"
              label="身份证号/护照号"
              min-width="200"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="gender"
              label="性别"
              min-width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="birthday"
              label="生日"
              min-width="110"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="orgName"
              label="所属分公司"
              min-width="210"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="light"
                  popper-class="customPopper"
                  :content="row.orgName"
                  placement="top"
                  v-if="row.orgName"
                >
                  <div class="description-div">
                    {{ row.orgName }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="parkName"
              label="所属园校"
              min-width="210"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="light"
                  popper-class="customPopper"
                  :content="row.parkName"
                  placement="top"
                  v-if="row.parkName"
                >
                  <div class="description-div">
                    {{ row.parkName }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="className"
              label="班级"
              min-width="150"
              align="center"
            >
            </el-table-column>
          </el-table>

          <!-- 分页 -->
          <div class="manger-footer">
            <div>
              <span class="footer-left-title">园校投保率：</span>
              <span
                :class="
                  infoRate < 100 ? 'footer-left-num' : 'footer-left-title'
                "
              >
                {{ infoRate || 0 }}%
              </span>
            </div>
            <div class="footer-pagination">
              <el-pagination
                background
                @size-change="handleSizeChange"
                layout="total,sizes, prev, pager, next, jumper"
                :total="pagination.total"
                :page-size="pagination.size"
                @current-change="handleCurrentChangePage"
                :current-page.sync="pagination.currentPage"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <el-dialog title="上传保单" :visible.sync="dialogFormVisible">
        <el-form>
          <el-form-item prop="fileList">
            <el-upload
              action="/webForm/dataDirectory/data-attachment/uploadFile"
              :headers="uploadHeader"
              :on-change="handleChange"
              :on-success="handleSuccess"
              :on-remove="handleRemove"
              :before-upload="beforeAvatarUpload"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleDatermineBtn">
            确 定
          </el-button>
        </div>
      </el-dialog>

      <el-tab-pane label="未投保预警">
        <div>
          <!-- 表单未投保预警 -->
          <el-form
            :model="formNotinsure"
            onsubmit="return false"
            :inline="true"
            class="search-form"
          >
            <el-form-item>
              <el-select
                v-model="formNotinsure.orgId"
                clearable
                placeholder="请选择分公司"
                @change="handleCompany"
              >
                <el-option
                  v-for="item in moduleCompanyOptions"
                  :key="item.label"
                  :label="item.name"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formNotinsure.parkId"
                clearable
                placeholder="请选择园校"
                :disabled="disabledPark"
                @change="handlePark"
              >
                <el-option
                  v-for="item in moduleParkOptions"
                  :key="item.label"
                  :label="item.name"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchFunNo"
              >
                查询
              </el-button>
            </el-form-item>

            <el-form-item>
              <el-button
                @click="resetFunNo"
                icon="el-icon-refresh-left"
                class="reset"
              >
                重置
              </el-button>
            </el-form-item>
          </el-form>

          <!-- 表格 -->
          <div>
            <el-row>
              <el-col :span="12">
                <div class="noinsure-title">变更前（已离园）</div>
                <div class="">
                  <el-table
                    :data="tableNotinsure.beforeList"
                    border
                    :header-cell-style="{
                      background: '#F1F4F9',
                      color: '#5E6D9B',
                      fontWeight: '400',
                      'text-align': 'center',
                    }"
                    v-loading="tableLoading"
                  >
                    <el-table-column
                      type="index"
                      label="序号"
                      width="60"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="policyholder"
                      label="姓名"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="parkName"
                      label="所属园校"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="identityNumber"
                      label="身份证号"
                      width="200"
                      align="center"
                    ></el-table-column>
                  </el-table>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="noinsure-title">变更后（新入园未投保）</div>
                <div>
                  <el-table
                    :data="tableNotinsure.afterList"
                    border
                    :header-cell-style="{
                      background: '#F1F4F9',
                      color: '#5E6D9B',
                      fontWeight: '400',
                      'text-align': 'center',
                    }"
                    v-loading="tableLoading"
                  >
                    <el-table-column
                      type="index"
                      label="序号"
                      width="60"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="policyholder"
                      label="姓名"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="parkName"
                      label="所属园校"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="identityNumber"
                      label="身份证号"
                      width="200"
                      align="center"
                    ></el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <onlinePreview
      v-if="attachmentFilelist.length > 0"
      :onlinePreviewVisible="onlinePreviewVisible"
      :type="fileAttachment.previewType"
      @closeOnlinePreviewDialog="closeOnlinePreviewDialog"
      :url="fileAttachment.url"
      :title="attachmentFilelist[0].attachmentName || ''"
    />
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import downloadFile from "@/utils/downloadFile";
import onlinePreview from "@/components/onlinePreview/index";
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      form: {
        orgId: "",
        parkId: "",
        classLabel: "",
        policyholder: "",
        fileList: [],
      },
      fileList: [],
      fileName: "",
      moduleCompanyOptions: [], //所属公司下拉
      moduleClassOptions: [], // 所属班级下拉
      moduleParkOptions: [], //所属园校下拉
      disabledClass: true, //是否禁用class选择框
      disabledPark: true,
      formNotinsure: {
        orgId: "",
        parkId: "",
      },
      tableData: [],
      tableNotinsure: [], //未投保
      pagination: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        size: 10, // 每页显示多少条
      },
      dialogFormVisible: false,
      tableLoading: false, // 表格loading
      infoRate: "", //园校投保率
      tabClickIndex: "",
      uploadHeader: { token: this.getToken() },
      fileAttachment: {},
      onlinePreviewVisible: false,
      attachmentFilelist: [],
      permissionButtonList: [], // 权限按钮list
      isUpload: false,
      isView: false,
      isExport: false,
      isUninsuredExport: false,
    };
  },

  components: {
    onlinePreview,
  },

  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
      userInfo: (state) => state.userInfo,
    }),
  },

  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          console.log(val, "huoshifeipermissionButtonList");
          this.isUpload = val.indexOf("z_childInsurance:upload") != -1;
          this.isView = val.indexOf("z_childInsurance:view") != -1;
          this.isExport = val.indexOf("z_childInsurance:export") != -1;
          this.isUninsuredExport =
            val.indexOf("z_childInsurance:uninsuredExport") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },

  created() {
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },

  mounted() {
    // this.tableLoading = true;
    // this.init();
    this.getInfoRate();
    this.getNotinsure();
    this.getCompanyDeptList();
    this.getAttachmentPage();
  },
  activated() {
    this.tableLoading = true;
    this.init();
  },

  methods: {
    getPermissionButton,

    getToken,
    /** 初始化 */
    init() {
      let params = {
        type: "1",
        orgId: this.form.orgId,
        parkId: this.form.parkId,
        classLabel: this.form.classLabel,
        policyholder: this.form.policyholder,
        current: this.pagination.currentPage,
        size: this.pagination.size,
      };

      this.$api.getInsuranceInfo(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.pagination.total = res.data.data.total;
          this.getInfoRate();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //园校投保率
    getInfoRate() {
      let params = {
        type: 1,
        orgId: this.form.orgId,
        parkId: this.form.parkId,
      };
      this.$api.getInfoRate(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.infoRate = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查看保单pdfpath
    getAttachmentPage() {
      let params = {
        type: 1,
      };
      this.$api.getAttachmentPage(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.attachmentFilelist = res.data.data.records;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查看文件
    showFile() {
      if (this.attachmentFilelist.length > 0) {
        if (this.attachmentFilelist[0].pdfPath) {
          let url = "mng/insurance/attachment/downloadFile";
          let data = {
            id: this.attachmentFilelist[0].attachmentId,
            type: 2,
            flag: 1,
          };
          let type = "get";
          this.onlinePreviewVisible = true;
          this.fileAttachment = {
            previewType: "pdf",
            url: this.attachmentFilelist[0].pdfPath,
            attachment_name: this.attachmentFilelist[0].attachment_name,
            attachment_type: this.attachmentFilelist[0].attachment_type,
          };
        } else {
          this.$message.error("暂不支持在线预览，请下载后查看");
          return;
        }
      } else {
        this.$message.warning("请上传后查看保单！");
      }
    },

    //查询分公司
    getCompanyDeptList() {
      let params = {
        type: "NODE_ORG",
      };
      this.$api.getCompanyDept(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleCompanyOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询分公司change事件
    handleCompany(val) {
      this.form.parkId = "";
      this.form.classLabel = "";
      this.formNotinsure.parkId = "";

      if (val == "") {
        this.disabledPark = true;
        this.disabledClass = true;
      } else {
        this.disabledPark = false;
        this.getSchoolList(val);
      }
    },

    //查询园校
    getSchoolList(valCompany) {
      let params = {
        parent: valCompany,
        type: "NODE_SCHOOL",
      };
      this.$api.getCompanyDept(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleParkOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询园校change事件
    handlePark(val) {
      this.form.classLabel = "";
      if (val == "") {
        this.disabledClass = true;
      } else {
        this.disabledClass = false;
        this.getClassInfoList(val);
      }
    },

    //查询班级
    getClassInfoList(valPark) {
      let params = {
        parentLabel: valPark,
      };
      this.$api.getClassInfo(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleClassOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 查询未投保信息
    getNotinsure() {
      let params = {
        type: "1",
        orgId: this.formNotinsure.orgId,
        parkId: this.formNotinsure.parkId,
        current: this.pagination.currentPage,
        size: this.pagination.size,
      };

      this.$api.getUninsuredInfo(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.tableNotinsure = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 查询
    searchFun() {
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.init();
    },

    // 重置
    resetFun() {
      this.form = {
        orgId: "",
        parkId: "",
        classLabel: "",
        policyholder: "",
      };
      this.disabledPark = true;
      this.disabledClass = true;
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.init();
    },

    // 查询
    searchFunNo() {
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.getNotinsure();
    },

    // 重置
    resetFunNo() {
      this.formNotinsure = {
        orgId: "",
        parkId: "",
      };
      this.disabledPark = true;
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.getNotinsure();
    },

    //上传文件
    handleSuccess(res, file, fileList) {
      this.fileName = file.name;
      this.uploading = false;
      if (res.code != 0) {
        /*上传失败 移除列表*/
        this.$message.error(res.msg);
        fileList.forEach((item, index) => {
          if (item.uid == file.uid) {
            fileList.splice(index, 1);
          }
        });
        return;
      }

      this.form.fileList.push({
        name: file.name,
        fileType: file.name.split(".")[file.name.split(".").length - 1],
        uid: file.uid,
        type: "1",
        size: file.size,
        url: res.data,
      });
    },

    //上传文件确定按钮
    handleDatermineBtn() {
      this.dialogFormVisible = false;

      const str = this.fileName;
      const index = str.lastIndexOf(".");
      const attachmentType = str.substring(index + 1, str.length);

      let params = {
        type: 1,
        attachmentName: this.fileName,
        path: this.fileList[0].response.data.fileUrl,
        pdfPath:
          this.fileList[0].response.data.pdfUrl ||
          this.fileList[0].response.data.fileUrl,
        attachmentType: attachmentType,
      };
      this.$api.getAttachmentSave(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.getAttachmentPage();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //删除上传文件
    handleRemove(file, fileList) {
      let uid = file.uid || file.id;
      this.fileList.forEach((item, index) => {
        if (item.uid == uid || item.id == uid) {
          this.fileList.splice(index, 1);
        }
      });
    },

    //上传前检查文件格式
    beforeAvatarUpload(file) {
      if (this.checkFileType(file.name)) {
        return file;
      }
      return false;
    },

    //检查文件格式
    checkFileType(name) {
      let regx = null;
      let type = name.split(".")[name.split(".").length - 1];

      regx = /PDF|pdf/;
      if (!regx.test(type)) {
        this.$message.error("文件格式有误,只支持PDF文件!");
        return false;
      }
      return true;
    },

    //只可上传一个文件
    handleChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; // 这一步，是 展示最后一次选择的csv文件
      }
    },

    /** 导出 */
    downLoadFile() {
      if (this.tableData.length > 0) {
        //下载文件
        let url = "/mng/insurance/info/exportList";

        let data = {
          level: "1",
          type: "1",
          orgId: this.form.orgId,
          parkId: this.form.parkId,
          classLabel: this.form.classLabel,
          policyholder: this.form.policyholder,
        };

        let type = "get";

        this.tableLoading = true;
        downloadFile({ url, data, type }).then(() => {
          this.tableLoading = false;
        });
      } else {
        this.$message.warning("暂无数据，不能导出！");
      }
    },

    /** 未投保导出 */
    downLoadFileNo() {
      if (
        this.tableNotinsure.beforeList == null &&
        this.tableNotinsure.afterList == null
      ) {
        this.$message.warning("暂无数据，不能导出！");
      } else {
        //下载文件
        let url = "/mng/insurance/info/exportUninsured";

        let data = {
          type: "1",
          orgId: this.formNotinsure.orgId,
          parkId: this.formNotinsure.parkId,
        };

        let type = "get";

        this.tableLoading = true;
        downloadFile({ url, data, type }).then(() => {
          this.tableLoading = false;
        });
      }
    },

    //tabs切换
    handleTabClick(tab, event) {
      console.log(tab.index);
      this.tabClickIndex = tab.index;
      this.tableLoading = true;
      this.pagination.currentPage = 1;
      if (tab.index == 0) {
        this.init();
      } else {
        this.getNotinsure();
      }
    },

    closeOnlinePreviewDialog() {
      this.fileAttachment = {};
      this.onlinePreviewVisible = false;
    },

    /** 分页 */
    handleCurrentChangePage(val) {
      this.pagination.currentPage = val;
      this.tableLoading = true;
      this.init();
    },

    handleSizeChange(val) {
      this.pagination.size = val;
      this.tableLoading = true;
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";

.contain {
  // width: calc(100% - 40px);
  background-color: #ffffff;
  padding: 26px 30px 30px 30px;
  box-sizing: border-box;

  .description-div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // /deep/.el-tabs__header {
  //   margin: 0 0 30px;
  // }

  /deep/.el-tabs--border-card > .el-tabs__content {
    padding: 0;
  }

  /deep/.el-tabs--border-card {
    background: #fff;
    border: none;
    box-shadow: none;
  }

  /deep/.el-tabs__nav {
    border: 1px solid #003685;
    border-radius: 5px;
  }

  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item {
    color: #003685;
  }

  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item {
    // border: 1px solid #003685;
  }

  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item:first-child {
    border-right-color: #003685;
    border-left-color: #003685;
    border-radius: 5px 0 0 5px;
    width: 120px;
    text-align: center;
  }
  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item:last-child {
    border-radius: 0 5px 5px 0;
    color: red;
  }

  /deep/.el-tabs--border-card > .el-tabs__header {
    background-color: transparent;
    border-bottom: none;
    margin: 0;
  }

  /deep/.el-tabs__item {
    font-size: 16px;
  }
  /deep/.el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item:not(.is-disabled):hover {
    color: #e7c69c;
  }

  /deep/.el-tabs__item:hover {
    color: #003685;
    cursor: pointer;
  }

  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    border-right-color: transparent;
    border-left-color: #003685;
    border-top-color: #003685;
    // border-radius: 5px 0 0 5px;
    background-color: #003685;
    color: #fff;
  }

  /deep/.el-tabs__active-bar {
    background-color: #2a00b2;
  }

  .noinsure-title {
    color: #606266;
    text-align: center;
    line-height: 50px;
    border: 1px solid #ebeef5;
  }

  .form-btn {
    display: flex;
    margin: 20px 0;

    .sheet-btn {
      background: #2a00b2;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }

  .search-form {
    margin-top: 20px;
  }

  .manger-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding-bottom: 5px;

    .footer-left-title {
      color: #606266;
    }
    .footer-left-num {
      color: red;
    }
  }
}
</style>
